import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import BgEffect from "../components/BgEffect"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import SEO from "../components/seo"
import logo from '../images/INMAGIK_COLOR.svg'
import get from 'lodash/get'


const ContactPage = ({data}) => {
  
  
  return (
  <Layout>
    <SEO title="Contacts - INMAGIK" />
    <div className="jumbo-title p-4 container-fluid text-center bg-black position-relative">

      {/* <BgEffect></BgEffect> */}

      <div className="display-3 font-200 text-white">INMAGIK srl</div>
      {/* <h1 className="display-4">INMAGIK</h1> */}

      <div className="text-center mb-4 mt-4">
        <img src={logo} width="80" height="80" className="" alt="inmagik logo"/>
      </div>
      
      <div className="display-medium font-200 px-5 text-white">
        <a href="mailto:info@inmagik.com">info@inmagik.com</a>
      </div>
      <div className="display-medium font-200 px-5 text-white">
        twitter: <a href="https://twitter.com/inmagiklabs">@inmagiklabs</a>
      </div>
      
      <hr/>

      <div className="lead font-200 text-white">
        <FormattedMessage id="legal_address" />: Via S.Orsola 2, 24122 Bergamo, Italy
      </div>
      <div className="lead font-200 text-white">
        <FormattedMessage id="operational_headquarters" />: Via Garibaldi 7, 24121 Bergamo, Italy
      </div>
     
    </div>
 
   
  </Layout>
)}

export default injectIntl(ContactPage)
